import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ThumbGrid from "./thumbnails"
import { Grid } from "@material-ui/core"



class Gallery extends Component {

  constructor(props) {
    super(props);
    this.images = props.images.map(({ node }) => node.childImageSharp.fluid.src);
    //this.images = props.images.map(({ node }) => node.childImageSharp.fixed.src);
    console.log(props.images);
    this.edges = props.images;

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }
  
  

  render() {
    const handleOpen = i => e => {
      this.state.photoIndex = i
      this.setState({ isOpen: true })
    }
    const onCloseRequest = () => {
      this.setState({ isOpen: false })
    }
    const onMovePrevRequest = () => {
      this.setState({
        photoIndex: (photoIndex + this.images.length - 1) % this.images.length
      })
    }
    const onMoveNextRequest = () => {
      this.setState({
        photoIndex: (photoIndex + 1) % this.images.length
      })
    }

    const { photoIndex, isOpen } = this.state;
    
    return (
      <div>
        <Grid container spacing={3} justify="center">
      <ThumbGrid images={this.edges} handleOpen={handleOpen} />
        
      </Grid>
        {isOpen && (
          <Lightbox
            mainSrc={this.images[photoIndex]}
            nextSrc={this.images[(photoIndex + 1) % this.images.length]}
            prevSrc={this.images[(photoIndex + this.images.length - 1) % this.images.length]}
            onCloseRequest={onCloseRequest}
            onMovePrevRequest={onMovePrevRequest}
            onMoveNextRequest={onMoveNextRequest}
          />
        )}
      </div>
    );
  }
}

export default Gallery