import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Img from "gatsby-image" // to take image data and render it
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, Grid } from "@material-ui/core"


class Gallery extends Component {

  constructor(props) {
    super(props);
    this.images = props.images.map(({ node }) => node.childImageSharp.fluid.src);
    this.cover = props.cover;
    //this.images = props.images.map(({ node }) => node.childImageSharp.fixed.src);
    console.log(props.images);
    this.edges = props.images;

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }



  render() {
    const handleOpen = i => e => {
      this.state.photoIndex = 0
      this.setState({ isOpen: true })
    }
    const onCloseRequest = () => {
      this.setState({ isOpen: false })
    }
    const onMovePrevRequest = () => {
      this.setState({
        photoIndex: (photoIndex + this.images.length - 1) % this.images.length
      })
    }
    const onMoveNextRequest = () => {
      this.setState({
        photoIndex: (photoIndex + 1) % this.images.length
      })
    }

    const { photoIndex, isOpen } = this.state;
    console.log(this.cover);
    return (
      <div>


        <ButtonBase onClick={handleOpen(0)} style={{
          display: "inline-block",
          background: "transparent",
          border: "none",
          padding: 0,
          margin: 0,
          width: "100%",
          height: "100%",
          borderRadius: 4,
          boxShadow: "0 0 25px -5px rgba(0,0,0,0.3)",
        }}>
          <Img
            fluid={this.cover}

            lazyProps={{ width: '50%', height: '100%' }}
          />
        </ButtonBase>
        {isOpen && (
          <Lightbox
            mainSrc={this.images[photoIndex]}
            nextSrc={this.images[(photoIndex + 1) % this.images.length]}
            prevSrc={this.images[(photoIndex + this.images.length - 1) % this.images.length]}
            onCloseRequest={onCloseRequest}
            onMovePrevRequest={onMovePrevRequest}
            onMoveNextRequest={onMoveNextRequest}
          />
        )}
      </div>
    );
  }
}

export default Gallery