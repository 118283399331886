import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Image } from 'components/atoms';
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby"



const useStyles = makeStyles(theme => ({
  folioItem: {
    position: 'relative',
    overflow: 'hidden',
    margin: theme.spacing(1, 0),
    //boxShadow: `0 1.5rem 4rem rgba(22,28,45,.05)`,
    //borderRadius: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(2),
    },
    '&:last-child': {
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
      },
    },
    '&:hover': {
      '& .folio__image': {
        transform: 'scale(1.02)',
      },
      '& .folio__info-wrapper': {
        transform: 'translateY(0)',
      },
    },
  },
  image: {
    transitionDuration: '.7s',
    transform: 'scale(1.0)',
    objectFit: 'cover',
    maxWidth: "400px",
    //maxHeight: "250px",
    //width: "auto",
    height: "auto"
    //maxWidth:"600px",
  },
  folioTitle: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 500,
    color: "#595959",
    textAlign: 'center',
  },
  folioSubtitle: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#595959",
    textAlign: 'center',
    margin: theme.spacing(1, 0),
  },
  grid: {
    display: 'flex',
    justifyContent: "space-around",
    //width:300,
    flexDirection: 'row',
    flexWrap: "wrap"
    /*[theme.breakpoints.up('sm')]: {
      height: 500,
      margin: '0 auto',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      maxHeight: '100%',
    },*/
  },

}));

class PeintureData {
  constructor(titre, artiste, slug, fluid, taille, prix) {
    this.titre = titre;
    this.artiste = artiste;
    this.slug = slug;
    this.fluid = fluid;
    this.taille = taille;
    this.prix = prix;
  }
}

const AutreOeuvres = props => {
  const { className, autres_oeuvres_images, autres_oeuvres_md, ...rest } = props;
  const classes = useStyles();


  var peintures = new Object();
  for (const node of autres_oeuvres_md.edges) {
    let mdPath = node.node.fileAbsolutePath;
    let path = mdPath.substring(0, mdPath.lastIndexOf('/'));
    let titre = node.node.frontmatter.titre;
    let artiste = node.node.frontmatter.artiste;
    let slug = node.node.frontmatter.slug;
    let taille = node.node.frontmatter.taille;
    let prix = node.node.frontmatter.prix;
    peintures[path] = new PeintureData(titre, artiste, slug, "", taille, prix);
  }


  for (const node of autres_oeuvres_images.edges) {
    let imagePath = node.node.absolutePath;
    let path = imagePath.substring(0, imagePath.lastIndexOf('/'));
    if (peintures[path]) {
      peintures[path] = new PeintureData(peintures[path].titre, peintures[path].artiste, peintures[path].slug, node.node.childImageSharp.fluid, peintures[path].taille, peintures[path].prix);
    }
  }

  let wrapper = [[], [], []];
  let hauteurs = [0, 0, 0];
  let row = [];

  for (var path in peintures) {
    let id_colonne = 0;
    if (hauteurs[0] < hauteurs[1] && hauteurs[0] < hauteurs[2]) {
      id_colonne = 0;
    } else if (hauteurs[1] <= hauteurs[0] && hauteurs[1] <= hauteurs[2]) {
      id_colonne = 1;
    } else if (hauteurs[2] <= hauteurs[1] && hauteurs[2] <= hauteurs[0]) {
      id_colonne = 2;
    }

    var peinture = peintures[path];
    hauteurs[id_colonne] = hauteurs[id_colonne] + peinture.fluid.presentationHeight;
    wrapper[id_colonne].push(peinture);
    row.push(peinture);
  }



  return (
    <div  >
      <div className={classes.grid}>
        {row.map((peinture, index) => (

          <div key={index} className={classes.itemContainer}>
            <div
              className={classes.folioItem}
              key={index}
              data-aos="fade-up"
            //style={{ height: item.h }}
            >
              <Link to={peinture.slug}>
                <Image
                  src={peinture.fluid.src}
                  className={clsx('folio__image', classes.image)}
                  lazy={false}
                />
              </Link>
              <div
                className={clsx(
                  'folio__info-wrapper',
                  classes.folioInfoWrapper,
                )}
              >


                <Typography
                  variant="body1"
                  className={classes.folioTitle}
                  color="textSecondary"
                >
                  {peinture.titre}
                </Typography>
                <Link to="/cuitong">
                  <Typography
                    variant="body1"
                    className={classes.folioSubtitle}
                    color="textSecondary"
                  >
                    {peinture.taille} - {peinture.prix} €

                  </Typography>
                </Link>

              </div>

            </div>


          </div>

        ))}
      </div>
    </div>
  );
};



export default AutreOeuvres;
