
import { graphql } from "gatsby"
import { makeStyles } from '@material-ui/core';
import Ei from 'layouts/Ei';
import WithLayout from 'WithLayout';
import { Peinture } from './components';
import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet"



export default function ArtisteTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {

  const data_peinture = data.markdownRemark.frontmatter;
  const images = data.allFile.edges;
  //document.getElementById('snipcart-styles').setAttribute('href','/snip.css');


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.markdownRemark.frontmatter.artiste + " - " + data.markdownRemark.frontmatter.titre}</title>
        <link rel="canonical" href={"https://elseink.com" + data.markdownRemark.frontmatter.slug} />
      </Helmet>
      <WithLayout
        component={() => Peinture({
          data_peinture: data.markdownRemark.frontmatter,
          images: data.allFile.edges,
          cover: data.cover.childImageSharp.fluid,
          minibio: data.minibio.frontmatter,
          avatar: data.avatar.childImageSharp.fixed,
          autres_oeuvres_images: data.autres_oeuvres_images,
          autres_oeuvres_md: data.autres_oeuvres_md
        })}
        layout={Ei}
      />
    </div>

  )
}

export const pageQuery = graphql`
  query($regex_md: String!, $regex_repimage: String!, $regex_cover: String!, $regex_avatar: String!, $regex_repartiste: String!, $regex_artistemd: String!) {
    markdownRemark(fileAbsolutePath: {regex: $regex_md}) {
      frontmatter {
        titre
        artiste
        prix
        taille
        delais
        slug
      }
    }
    allFile(filter: {absolutePath: {regex: $regex_repimage}}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              
            }
            fixed (width: 1200){
              src
            }
          }
        }
      }
    }
    avatar:file(absolutePath: {regex: $regex_avatar}) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    minibio:markdownRemark(fileAbsolutePath: {regex: $regex_artistemd}) {
      frontmatter {
        nom
        minibio
      }
    }
    cover:file (absolutePath: {regex: $regex_cover}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    autres_oeuvres_images:allFile(filter: {absolutePath: {regex: "/cover/"}}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
              presentationHeight
            }
          }
          absolutePath
        }
      }
    }
    
    autres_oeuvres_md:allMarkdownRemark(filter: {frontmatter: {type: {regex: "/peinture/"}, featured: {eq: "true"}}, fileAbsolutePath: {regex: $regex_repartiste}}) {
      edges {
            node {
              fileAbsolutePath
              frontmatter {
                titre
                artiste
                slug
                prix
                taille
              }
            }
          }
        }
  }
`