
import React from 'react'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { Image } from 'components/atoms';
import { useMediaQuery, Typography, GridList, GridListTile, IconButton } from '@material-ui/core';
import { Link } from "gatsby"


const useStyles = makeStyles((theme) => ({
  nom: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: "#000000",
  },
  prix: {
    paddingTop: 15,
    fontFamily: "Inconsolata",
    fontSize: "20px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  description: {
    paddingTop: 50,
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "27px",
    fontWeight: 400,
    color: "#000000",
  },
  taille: {
    paddingTop: 20,
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  technique: {
    paddingTop: 15,
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  bouton: {
    boxSizing: "border-box",
    display: "inline-block",
    fontFamily: "Inconsolata,sans-serif",
    fontWeight: 400,
    letterSpacing: ".3em",
    lineHeight: "2em",
    margin: 50,
    outline: 0,
    position: "relative",
    textTransform: "uppercase",
    verticalAlign: "middle",
    width: "auto",
    fontSize: "14px",
    padding: "10px 83px",
    color: "#595959",
    backgroundColor: "transparent",
    border: "1px solid #595959",
    borderRadius: "0px",
  },

}));


const Description = props => {
  const { data_peinture, className, cover, ...rest } = props;
  const classes = useStyles();


  // className={`${classes.bouton} "snipcart-add-item"`}
  return (
    <div>
      <div className={classes.nom}>{data_peinture.titre}</div>
      <Link to={"/" + data_peinture.slug.split("/")[1]}><div className={classes.technique}>Artiste: {data_peinture.artiste}</div></Link>
      <div className={classes.technique}>Taille: {data_peinture.taille}</div>
      <div className={classes.technique}>Délais: {data_peinture.delais}</div>
      <div className={classes.technique}>Livraison dans un colis protecteur</div>
      <div className={classes.technique}>Série limitée à 200 exemplaires</div>


      <div className={classes.prix}>{data_peinture.prix} €</div>

      <Button

        fullWidth
        size="large"
        className={`${classes.bouton} snipcart-add-item`}
        data-item-id={data_peinture.artiste + "_" + data_peinture.titre}
        data-item-price={data_peinture.prix}
        data-item-url={data_peinture.slug}
        data-item-description={data_peinture.taille}
        data-item-image={cover.src}
        data-item-name={data_peinture.titre}
      >
        acheter
              </Button>
    </div>
  );
};

/*
      <Button

        fullWidth
        size="large"
        className={`${classes.bouton} snipcart-add-item`}
        data-item-id={data_peinture.artiste + "_" + data_peinture.titre}
        data-item-price={data_peinture.prix}
        data-item-url={data_peinture.slug}
        data-item-description={data_peinture.taille}
        data-item-image={cover.src}
        data-item-name={data_peinture.titre}
      >
        acheter
                      </Button>

*/

export default Description;
