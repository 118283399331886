
import React from 'react'
import Paper from '@material-ui/core/Paper';
//import Grid from '@material-ui/core/Grid';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { useMediaQuery, Typography, GridList, GridListTile, IconButton } from '@material-ui/core';
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image" // to take image data and render it
import { Description, Gallery, AutreOeuvres, CoverGallerie } from '../../components';
import { Section, SectionAlternate } from 'components/organisms';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from "gatsby";


import CuitongImage from "../../../../assets/artistes/cuitong/cuitong.jpg"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  image: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(10),
      marginRight: theme.spacing(10),
    },

    //marginBottom: theme.spacing(10),
    //width: 200,
    //height: 200,
  },
  avatar: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(5),
    },
    //margin: theme.spacing(10),
    //width: 200,
    //height: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  nom: {
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: "#000000",
  },
  titre: {
    paddingTop: 5,
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    letterSpacing: "4px",
  },
  bio: {
    paddingTop: 45,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
  },
  breadcrumbs: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "27px",
    fontWeight: 400,
    color: "#808080",

  },
  breadcrumbsSelected: {
    fontFamily: "Montserrat",
    fontSize: "14px",
    lineHeight: "27px",
    fontWeight: 400,
    color: "#000000",

  },
  nom: {
    paddingLeft: 45,
    fontFamily: "Montserrat",
    fontSize: "50px",
    fontWeight: 500,
    color: "#000000",
  },
  bio: {
    paddingLeft: 45,
    fontFamily: "Montserrat",
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "27px",
  },
  artiste: {
    paddingTop: 45,
    paddingBottom: 45,
  },
  ligne_button: {
    height: "100px",
    position: "relative",
    paddingTop: "10px",

  },
  bouton: {
    margin: 0,
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    //transform: "translateY(-50%)",

    boxSizing: "border-box",
    display: "inline-block",
    fontFamily: "Inconsolata,sans-serif",
    fontWeight: 400,
    letterSpacing: ".3em",
    lineHeight: "2em",
    //margin: 50,
    outline: 0,
    //position: "relative",
    textTransform: "uppercase",
    verticalAlign: "middle",
    width: "auto",
    fontSize: "14px",
    padding: "10px 83px",
    color: "#595959",
    backgroundColor: "transparent",
    border: "1px solid #595959",
    borderRadius: "0px",
  },
  gallery: {
    [theme.breakpoints.down('xs')]: {
      display: "none",
    },
  },
  gallery_smart: {
    display: "none",
  },
}));

const Peinture = props => {
  const { data_peinture, minibio, images, cover, avatar, autres_oeuvres_images, autres_oeuvres_md, className, ...rest } = props;
  const classes = useStyles();
  let artisteLink = "/" + data_peinture.slug.split('/')[1];
  const desktop = useMediaQuery('(min-width:600px)');
  return (
    <div >
      <SectionAlternate className={classes.integrationsSection}>

        <Grid container >

          <Grid item xs={12} sm={8} md={8} >

            <div className={classes.image}>

              <CoverGallerie images={images} cover={cover} />


            </div>
          </Grid>

          <Grid item sx={6} sm={4} md={4}>

            <div className={classes.description}>
              <Description data_peinture={data_peinture} cover={cover} />
            </div>

          </Grid>

        </Grid>

      </SectionAlternate>

      <Grid container className={classes.artiste}>
        <Grid item sx={0} sm={2} md={2}>


        </Grid>

        <Grid item sx={6} sm={12} md={1}>
          <Link to={"/" + data_peinture.slug.split("/")[1]}>
            <Img
              fixed={avatar}
              className={classes.avatar}
              lazyProps={{ width: '100%', height: '100%' }}
            /></Link>
        </Grid>

        <Grid item sx={6} sm={12} md={7}>
          <Link to={"/" + data_peinture.slug.split("/")[1]}><div className={classes.nom}>{minibio.nom}</div></Link>
          <div className={classes.bio}>{minibio.minibio}... <Link to={"/" + data_peinture.slug.split("/")[1]}>voir plus</Link></div>
        </Grid>

      </Grid>

      <AutreOeuvres autres_oeuvres_images={autres_oeuvres_images}
        autres_oeuvres_md={autres_oeuvres_md} />
      <div className={classes.ligne_button}>
        <Link to={"/" + data_peinture.slug.split("/")[1]}>
          <Button
            fullWidth
            size="large"
            className={classes.bouton}

          >Voir ses oeuvres</Button></Link>
      </div>
    </div>

  );
};

export default Peinture;